import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { View, Text } from "../../Themed";
import { CigarAttributes } from "appshared-ts/lib/types/cigar-types";
import { FunButton } from "../../pretty/FunButton";

interface Props {
  cigarAttrs: CigarAttributes;
}

function Row({ name, value }: { name: string; value: any }) {
  return (
    <View style={T("bg-tp wp100")}>
      <View style={T("bg-tp flx-row aib wp100")}>
        <Text style={T("bold mr5 f3")}>{name}</Text>
        <Text style={T("f2")}>{value || "-"}</Text>
      </View>
    </View>
  );
}

export function CigarQuickStats({ cigarAttrs }: Props) {
  const {
    length,
    strength,
    size,
    filler,
    binder,
    wrapper,
    country,
    tastingNotes,
  } = cigarAttrs || {};
  return (
    <View style={T("bg-tp aic pl4")}>
      <Row name="Length" value={length} />
      <Row name="Strength" value={strength} />
      <Row name="Size" value={size} />
      <Row name="Filler" value={filler} />
      <Row name="Binder" value={binder} />
      <Row name="Wrapper" value={wrapper} />
      <Row name="Country" value={country} />
      <View style={T("pt6")} />
      <View style={T("bg-tp wp100")}>
        <View style={T("bg-tp aic wp100")}>
          <Text style={T("bold mr3 f4")}>{"Tasting notes"}</Text>
        </View>

        <View style={T("bg-tp flx-row wp100 jcc flx-wrap")}>
          {(tastingNotes || []).map((flavor) => (
            <FunButton viewStyle={T("ma1")} filled={true} key={flavor}>
              <Text style={T("textDark pl2 pr2 bold")}>{flavor}</Text>
            </FunButton>
          ))}
        </View>
      </View>
    </View>
  );
}
