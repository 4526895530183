import { httpService } from "apputils/lib/service/http-service";
import {
  Cigar,
  CigarId,
  CigarReview,
  CigarReviewDetails,
  CigarReviewPermissions,
} from "appshared-ts/lib/types/cigar-types";

import {
  UserId,
  UiUser,
  NewAccountInfo,
  UserInfo,
} from "appshared-ts/lib/types/user-types";
import { CigarPurchase } from "appshared-ts/lib/types/purchase-types";

const BACKEND_URL = "/api";
const url = (path: string) => `${BACKEND_URL}${path}`;

// you could auto-generate this from the endpoint definitions

export const backendService = {
  login: httpService.httpPost<
    {
      userId: string;
      password: string;
    },
    {
      userId: string;
    }
  >(url("/login")),

  getUserData: httpService.httpGetNoData<UiUser>(url("/user/profile")),

  newAccount: httpService.httpPost<
    NewAccountInfo,
    {
      userId: string;
    }
  >(url("/account/new")),

  updateUser: httpService.httpPut<
    Partial<{
      info: Partial<UserInfo>;
    }>,
    string
  >(url("/user")),

  logout: httpService.httpPost<void, void>(url("/logout")),

  reviewCigar: httpService.httpPost<
    {
      cigarId: string;
      rating: number;
      tastingNotes: string[];
    },
    void
  >(url("/review-cigar")),

  getMyCigarReviews: httpService.httpGetNoData<CigarReview[]>(
    url("/user/cigar-reviews")
  ),

  searchCigars: httpService.httpGet<
    {
      searchTermEncoded: string;
    },
    Cigar[]
  >(url("/cigar/search")),

  getCigar: httpService.httpGet<
    {
      cigarId: number;
    },
    Cigar
  >(url("/cigar")),

  getWebappConf: httpService.httpGet<
    {
      gitRev: string;
    },
    {
      targetVsn: string;
    }
  >(url("/webapp-conf")),

  saveCigarReview: httpService.httpPost<
    {
      cigarId: number;
      review: CigarReviewDetails;
      permissions: CigarReviewPermissions;
      purchaseTs?: number | null;
    },
    string
  >(url("/cigar/review")),

  getCigarReviewComplete: httpService.httpGet<
    {
      cigarId: number;
      userId: string;
      reviewTs: number;
    },
    {
      review: CigarReview;
      cigar: Cigar;
    }
  >(url("/cigar/review")),

  activateUser: httpService.httpPost<
    {
      activationKey: string;
    },
    {
      userId: UserId;
      pw: string;
    }
  >(url("/user/activate")),

  changePassword: httpService.httpPost<
    {
      oldPassword: string;
      newPassword: string;
      userId?: string;
    },
    void
  >(url("/user/change-password")),

  getMyPurchases: httpService.httpGetNoData<CigarPurchase[]>(
    url("/user/purchases")
  ),

  clearPurchase: httpService.httpPost<
    {
      cigarId: CigarId;
      purchaseTs: number;
    },
    void
  >(url("/user/purchase-clear")),

  resetPassword: httpService.httpPost<void, void>(
    url("/user/authed-password-reset")
  ),

  renewSession: httpService.httpPost<
    {
      refreshToken: string;
    },
    void
  >(url("/user/renew-session")),
};
