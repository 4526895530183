import React from "react";

import { createStore } from "apputils";
import { UserState, defaultUserState } from "../../state/userState";
import { Store } from "apputils/lib/util/createStore";
import { defaultMiscState, MiscState } from "../../state/miscState";
import {
  ActiveReviewState,
  defaultActiveReviewState,
} from "../../state/activeReviewState";

export interface AppContextData {
  userStore: Store<UserState>;
  miscStore: Store<MiscState>;
  activeReviewStore: Store<ActiveReviewState>;
}

const AppContext = React.createContext({} as AppContextData);

function init(): AppContextData {
  /**
   * Create global stores here.
   * You can still have the 'useGlobalState' hook on the store, within each component.
   */
  const contextData = {
    userStore: createStore<UserState>(defaultUserState),
    miscStore: createStore<MiscState>(defaultMiscState),
    activeReviewStore: createStore<ActiveReviewState>(defaultActiveReviewState),
  };
  // applyActiveReviewStateLogics(contextData.activeReviewStore);
  return contextData;
}

export function AppContextWrapper({ children }) {
  const [globalState, _setGlobalState] = React.useState(init());
  return (
    <AppContext.Provider value={globalState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return React.useContext(AppContext);
}
