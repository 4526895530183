import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { TextStyle, TouchableOpacity, ViewStyle } from "react-native";

import { Text, View } from "../Themed";
import { palette } from "../../constants/Colors";

interface Props {
  children: React.ReactNode;
  btnType?: "primary" | "secondary";
  onPress?: () => void;
  filled?: boolean;
  viewStyle?: ViewStyle;
}

const styles = {
  primary: {
    color: palette.revolYellow,
  },
  secondary: {
    color: palette.revolGray,
  },
};

export function FunButton({
  children,
  btnType,
  onPress,
  filled,
  viewStyle,
}: Props) {
  const actualBtnType = btnType || "primary";
  const actualStyles: ViewStyle = {
    borderColor: styles[actualBtnType].color,
    backgroundColor: filled ? styles[actualBtnType].color : "transparent",
    borderRadius: 5,
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[T("ba bold pa1 flx-row aic"), actualStyles, viewStyle]}>
        {children}
      </View>
    </TouchableOpacity>
  );
}
