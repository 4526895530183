import * as React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { useSessionLogic } from "../hooks/logic/useSessionLogic";
import {
  Banner,
  Divider,
} from "../components/feature/signup-login/SignupLoginCommon";
import { inputService } from "../service/inputService";

export const LoginScreen = asScreen(() => {
  const [phone, setPhone] = React.useState("");
  const [pw, setPw] = React.useState("");
  const [err, setErr] = React.useState("");
  const { initSession } = useSessionLogic();
  async function attemptLogin() {
    const { ok, data } = inputService.phoneAsUserId(phone);
    if (!ok) {
      setErr("Login failed");
      return;
    }
    await initSession(data!, pw);
  }

  return (
    <View style={T("hp100 aic")}>
      <View style={T("hp10")} />
      <Banner />
      <View style={T("hp5")} />
      <Divider />
      <View style={T("hp10")} />

      <Text style={[T("err pb4"), err ? null : T("o-0")]}>Error: {err}</Text>

      <View style={T("wp100")}>
        <TextInput
          onChangeText={(text) => setPhone(text)}
          label={SS["login.userId"]}
        />
        <View style={T("pt2")} />
        <TextInput
          onChangeText={(text) => setPw(text)}
          label={SS["login.pw"]}
          secureTextEntry={true}
        />
        <View style={T("pt5")} />
        <Button onPress={attemptLogin} title={SS["login.submit"]} />
      </View>

      {/* <View style={T("hp15")} />
      <View style={T("wp100 aic")}>
        <Text style={T("mb3")}>{SS["signup.prompt"]}</Text>
        <AppLink route={ROUTES.SIGNUP}>
          <Text style={T("bold")}>{SS["signup.here"]}</Text>
        </AppLink>
        </View> */}
    </View>
  );
});
