import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { View, Text, TextInput } from "../../Themed";
import { ReviewSelectable } from "./ReviewSelectable";
import {
  useReviewBurn,
  useReviewCanProceed,
} from "../../../hooks/activeReviewHooks";
import { textInputBgColor } from "./constants";
import { SS } from "../../../content/short-strings";
import { BurnQuality } from "appshared-ts/lib/types/cigar-types";

export function ConstructionBurnReviewStep() {
  const { burnQuality, setBurnQuality, burnNotes, setBurnNotes } =
    useReviewBurn();
  const { setCanProceed } = useReviewCanProceed();
  React.useEffect(() => {
    if (burnQuality === null) {
      setCanProceed(false);
    } else {
      setCanProceed(true);
    }
  }, [burnQuality]);
  const onPress = (forQuality: BurnQuality) => () => {
    setBurnQuality(forQuality);
  };

  return (
    <View style={T("bg-tp")}>
      <Text style={T("f4")}>How was the burn?</Text>
      <View style={T("bg-tp hp10")} />
      <View style={T("bg-tp wp100 flx-row")}>
        <ReviewSelectable
          containerStyle={T("flx-i")}
          isActive={burnQuality === "consistent"}
          onPress={onPress("consistent")}
          text="Consistent"
        />

        <ReviewSelectable
          containerStyle={T("flx-i")}
          textStyle={T("flx-i aic")}
          isActive={burnQuality === "inconsistent"}
          onPress={onPress("inconsistent")}
          text="Inconsistent"
        />
      </View>
      <View
        style={[
          T("mt3"),
          {
            backgroundColor: textInputBgColor,
          },
        ]}
      >
        <TextInput
          value={burnNotes}
          style={T("f1")}
          multiline={true}
          disableBorder={true}
          numberOfLines={3}
          onChangeText={setBurnNotes}
          placeholder={SS["review.burn.notes.placeholder"]}
        />
      </View>
    </View>
  );
}
