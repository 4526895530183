import { UiUser } from "appshared-ts/lib/types/user-types";

export interface UserState extends UiUser {}

export const defaultUserState: UserState = {
  userId: "",
  info: {
    firstName: "",
    lastName: "",
  },
};
