import { Dimensions } from "react-native";

const windowDimensions: any = {
  height: null,
  width: null,
};

export const dimensionService = {
  setIfNotSet() {
    if (!windowDimensions.height || !windowDimensions.width) {
      const { height, width } = Dimensions.get("window");
      windowDimensions.height = height;
      windowDimensions.width = width;
    }
  },

  getDimensions(): { height: number; width: number } {
    return windowDimensions;
  },
};
