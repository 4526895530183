import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { palette } from "../../constants/Colors";
import { View } from "../Themed";
import { ViewStyle } from "react-native";

interface Props {
  style?: ViewStyle;
}

export const Divider = ({ style }: Props) => {
  return (
    <View
      style={[
        T("bb wp75"),
        {
          borderColor: palette.textMed,
        },
        style,
      ]}
    />
  );
};
