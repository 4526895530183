import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { View, Text, TextInput } from "../../Themed";
import { ReviewSelectable } from "./ReviewSelectable";
import {
  useReviewBurn,
  useReviewCanProceed,
  useReviewSimpleFlavors,
} from "../../../hooks/activeReviewHooks";
import { textInputBgColor } from "./constants";
import { SS } from "../../../content/short-strings";
import { BurnQuality } from "appshared-ts/lib/types/cigar-types";

export function SimpleFlavorsReviewStep() {
  const { strength, setStrength } = useReviewSimpleFlavors();

  const onPress = (forStrength: string) => () => {
    setStrength(forStrength);
  };
  const { canProceed, setCanProceed } = useReviewCanProceed();

  React.useEffect(() => {
    if (strength) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [strength]);

  return (
    <View style={T("bg-tp hp100")}>
      <Text style={T("f4")}>How strong was it?</Text>
      <View style={T("bg-tp hp10")} />
      <View style={T("bg-tp wp100 flx-row")}>
        <ReviewSelectable
          containerStyle={T("flx-i")}
          isActive={strength === "mild"}
          onPress={onPress("mild")}
          text="Mild"
        />

        <ReviewSelectable
          containerStyle={T("flx-i")}
          textStyle={T("flx-i aic")}
          isActive={strength === "medium"}
          onPress={onPress("medium")}
          text="Medium"
        />

        <ReviewSelectable
          containerStyle={T("flx-i")}
          textStyle={T("flx-i aic")}
          isActive={strength === "strong"}
          onPress={onPress("strong")}
          text="Strong"
        />
      </View>
      <View
        style={[
          T("mt3"),
          {
            backgroundColor: textInputBgColor,
          },
        ]}
      ></View>
    </View>
  );
}
