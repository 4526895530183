import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { View } from "../Themed";
import { LogoOne } from "./LogoOne";

export function Loading() {
  return (
    <View style={T("wp100 hp100 aic jcc")}>
      <LogoOne height={125} width={250} />
    </View>
  );
}
