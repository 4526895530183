import React from "react";
import { Image } from "react-native";
import { T } from "@shipt/react-native-tachyons";

import { palette } from "../../../constants/Colors";
import { Text, View } from "../../Themed";
import { SS } from "../../../content/short-strings";

export function Divider() {
  return (
    <View
      style={[
        T("wp50 bb"),
        {
          borderColor: palette.revolYellow,
        },
      ]}
    />
  );
}

const bannerImgStyle = {
  height: 125,
  width: 200,
};
export function Banner() {
  return (
    <View style={T("aic jcc")}>
      <Image
        style={bannerImgStyle}
        source={require("../../../assets/images/logo1.png")}
      />
      <Text
        style={[
          T("f5 pt2 bold tc revolWhite"),
          {
            textShadowColor: palette.textDark,
            textShadowRadius: 2,
          },
        ]}
      >
        {SS["title"]}
      </Text>
    </View>
  );
}
