import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { palette } from "../../../constants/Colors";

import { View, Text } from "../../Themed";
import { TextStyle, TouchableOpacity, ViewStyle } from "react-native";

interface Props {
  text: string;
  isActive: boolean;
  onPress: () => void;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
}

export function ReviewSelectable({
  text,
  isActive,
  onPress,
  containerStyle,
  textStyle,
}: Props) {
  return (
    <TouchableOpacity style={[containerStyle]} onPress={onPress}>
      <Text
        style={[
          T("ba bold pa2"),
          {
            borderColor: palette.revolYellow,
            backgroundColor: isActive ? palette.revolYellow : "transparent",
            color: isActive ? palette.textDark : palette.textLight,
          },
          textStyle,
        ]}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
}
