import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { backendService } from "../service/backendService";
import { Image } from "react-native";
import { useDeviceSize } from "../hooks/useDeviceSize";
import { CigarReviewMain } from "../components/feature/review/CigarReviewMain";
import { useSetCigarReviewId } from "../hooks/activeReviewHooks";
import { Cigar } from "appshared-ts/lib/types/cigar-types";
import { useFocusEffect } from "@react-navigation/core";

const imageSize = {
  mobile: {
    height: 350,
    width: 350,
  },
};

export const CigarReviewScreen = asScreen<{
  cigarIdStr: string;
  purchaseTsStr?: string;
}>(({ cigarIdStr, purchaseTsStr }) => {
  console.log("rendering review screen");
  const cigarId = parseInt(cigarIdStr, 10);
  const purchaseTs = purchaseTsStr ? parseInt(purchaseTsStr, 10) : null;
  useSetCigarReviewId({ cigarId, purchaseTs });
  const [cigarDetails, setCigarDetails] = React.useState<Cigar | null>(null);
  const deviceSize = useDeviceSize();

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const res = await backendService.getCigar({ cigarId });
        setCigarDetails(res.data!);
      })();
    }, [cigarId])
  );

  const imgStyle = imageSize[deviceSize];

  if (!cigarDetails) {
    return <></>;
  }
  const {
    info: { name, imgSrc },
  } = cigarDetails!;
  return (
    <View key="page-pane" style={T("aic hp100")}>
      <View style={T("pt6 bg-tp aic wp100")}>
        <Image style={imgStyle} source={{ uri: imgSrc }} />
        <View style={[T("absolute bg-black o-60"), imgStyle]} />
      </View>

      <View key="overlaid-pane" style={T("absolute bg-tp aic hp100")}>
        <Text style={T("f4 tc shadow-1")}>Review your {name}</Text>
        <View style={T("bg-tp hp10")} />

        <View key="review-container" style={T("bg-tp wp100 flx-i")}>
          <CigarReviewMain cigar={cigarDetails} />
        </View>
      </View>
    </View>
  );
});
