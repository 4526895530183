// export type FlavorGroup

export const FLAVORS = {
  "herb": [
    "fern", "grass", "hay", "tea", "wet leaves",
  ],
  "wood": [
    "cedar", "oak", "pencil", "pine sap", "sandalwood", "shavings",
  ],
  "mineral": [
    "graphite", "iodine",
  ],
  "earth": [
    "humus", "moss", "mushroom", "peat", "truffle", "undergrowth", "yeast",
  ],
  "animal": [
    "leather", "manure", "meat", "milk", "stable", "venison",
  ],
  "pepper": [
    "black pepper", "green chili", "red pepper", "white pepper",
  ],
  "spice": [
    "allspice", "anise", "balsamic", "bittersweet", "cardamom",
    "cinnamon", "cloves", "cumin", "fresh spice",
    "ginger", "licorice", "nutmeg", "sweet spice", "turmeric", "vanilla",
  ],
  "flowers": [
    "dried flowers",
    "lavender",
  ],
  "fruit": [
    "black currant",
    "citrus",
    "dried fruit",
    "prune",
    "raspberry",
    "ripe fruit",
  ],
  "sweet": [
    "rum",
    "pastry",
    "nougat",
    "marzipan",
    "honey",
    "gingerbread",
    "chocolate milk",
    "dark chocolate",
    "cappuccino",
    "brown sugar",
  ],
  "nut": [
    "walnut",
    "peanut",
    "hazelnut",
    "chestnut",
    "cashew",
    "Brazil nut",
    "bitter almond",
    "almond",
  ],
  "toasted": [
    "toasted bread",
    "smoked",
    "roasted meat",
    "malt",
    "coffee",
    "bitter cocoa",
    "cocoa",
    "caramel",
    "brioche",
    "bread",
    "biscuit"
  ],
  "other": [
    "tar",
    "sulfur",
    "pharmaceutical",
    "fermented hay",
    "dust",
    "coal",
    "cardboard",
    "ammonia",
  ],
};

export const FLAVOR_GROUPS = Object.keys(FLAVORS).map((s) => s.toUpperCase());

// Object.keys(FLAVORS).forEach((key) => {
//   FLAVORS[key].forEach((flavor) => {
//     console.log(flavor);
//   })
// });
