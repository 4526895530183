import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Navbar, NO_NAVBAR_ROUTES } from "../components/navbar/Navbar";
import { View } from "../components/Themed";

import { SessionGuardWrapper } from "../components/wrapper/SessionGuardWrapper";
import { Layout } from "../constants/Layout";
import { DIMENSIONS } from "../constants/dimensions";
import { useDeviceSize } from "../hooks/useDeviceSize";

export function asScreen<T>(component: (params: T) => React.ReactNode) {
  // this part does not run in render

  return function ScreenWrapper({ route, navigation }) {
    const params: T = route.params || {};

    const showNavbar = !NO_NAVBAR_ROUTES.includes(route.name);
    const deviceSize = useDeviceSize();

    // const [windowSize, setWindowSize] = React.useState(Dimensions.get("window"));
    const { height, width } = Layout.window;
    const screenHeight = showNavbar
      ? height - DIMENSIONS.NAVBAR[deviceSize].height
      : height;

    return (
      <SessionGuardWrapper
        refreshToken={(params as any).refreshToken}
        routeName={route.name}
      >
        <View key="full-screen" style={[{ height, width }]}>
          {showNavbar ? <Navbar /> : null}
          <View
            key="screen-container"
            style={[T("pa3"), { height: screenHeight }]}
          >
            {component(params)}
          </View>
        </View>
      </SessionGuardWrapper>
    );
  };
}
