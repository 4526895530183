import { Component } from "react";
import { CigarQuickStats } from "../cigar/CigarQuickStats";
import { ConstructionBurnReviewStep } from "./ConstructionBurnReviewStep";
import { FlavorsReviewStep } from "./FlavorsReviewStep";
import { OverallReviewStep } from "./OverallReviewStep";
import { ReviewStepConfig } from "./review-types";
import { ReviewDoneStep } from "./ReviewDoneStep";
import { SimpleFlavorsReviewStep } from "./SimpleFlavorsReviewStep";

export const reviewStepOrder: ReviewStepConfig[] = [
  {
    stepName: "begin",
    Component: CigarQuickStats,
  },
  {
    stepName: "simple-flavors",
    Component: SimpleFlavorsReviewStep,
    skippable: true,
  },
  {
    stepName: "construction-burn",
    Component: ConstructionBurnReviewStep,
    skippable: true,
  },
  {
    stepName: "flavors",
    Component: FlavorsReviewStep,
    skippable: true,
  },
  {
    stepName: "overall",
    Component: OverallReviewStep,
  },
];
