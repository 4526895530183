import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { Entypo } from "@expo/vector-icons";
import { Menu } from "react-native-paper";

import { CigarSearchInput } from "../search/CigarSearchInput";

import { Button, Text, TextInput, View } from "../Themed";
import { LogoOne } from "../pretty/LogoOne";
import { useDeviceSize } from "../../hooks/useDeviceSize";
import { AppLink } from "../util/AppLink";
import { DIMENSIONS } from "../../constants/dimensions";
import { palette } from "../../constants/Colors";
import { DrawerActions, useNavigation } from "@react-navigation/core";
import { ROUTES } from "../../constants/ROUTES";

export const NO_NAVBAR_ROUTES = [ROUTES.LOGIN, ROUTES.SIGNUP, ROUTES.ACTIVATE];

export function Navbar() {
  const deviceSize = useDeviceSize();
  const nav = useNavigation();
  const [menuVisible, setMenuVisible] = React.useState(false);

  const { height, logoWidth } = DIMENSIONS.NAVBAR[deviceSize];
  const logoHeight = logoWidth * 0.5;
  function closeMenu() {
    setMenuVisible(false);
  }
  function openMenu() {
    setMenuVisible(true);
  }
  function menuNavigate(route: ROUTES) {
    nav.navigate(route);
    setMenuVisible(false);
  }

  const menuOpenBtn = (
    <Entypo
      onPress={openMenu}
      name="menu"
      size={32}
      color={palette.revolYellow}
    />
  );

  const menu = (
    <Menu
      contentStyle={T("bg-rg")}
      visible={menuVisible}
      onDismiss={closeMenu}
      anchor={menuOpenBtn}
    >
      <Menu.Item
        icon={() => (
          <Entypo name="shopping-bag" size={24} color={palette.revolYellow} />
        )}
        titleStyle={T("revolYellow bold f3")}
        onPress={() => menuNavigate(ROUTES.MY_PURCHASES)}
        title="My purchases"
      />
      <Menu.Item
        icon={() => (
          <Entypo name="star" size={24} color={palette.revolYellow} />
        )}
        titleStyle={T("revolYellow bold f3")}
        onPress={() => menuNavigate(ROUTES.MY_REVIEWS)}
        title="My reviews"
      />
      <Menu.Item
        icon={() => <Entypo name="key" size={24} color={palette.revolYellow} />}
        titleStyle={T("revolYellow bold f3")}
        onPress={() => menuNavigate(ROUTES.CHANGE_PASSWORD)}
        title="Change password"
      />
    </Menu>
  );
  return (
    <View style={[T("flx-row aifs wp100 aic pt1 pb3 pl4 pr4"), { height }]}>
      <AppLink route={ROUTES.MY_PURCHASES}>
        <LogoOne height={logoHeight} width={logoWidth} />
      </AppLink>
      <View key="nav-controls" style={T("pl5 flx-row aic jcfe flx-i")}>
        <CigarSearchInput />
        <View style={T("pr2")} />
        {menu}
      </View>
    </View>
  );
}
