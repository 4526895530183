/*
 * Only renders the contents if we have a session.
 * If not, redirect to the login route.
 */
import React from "react";
import { useSessionLogic } from "../../hooks/logic/useSessionLogic";
import { ROUTES } from "../../constants/ROUTES";
import { useAppState } from "../../hooks/useAppState";
import { Loading } from "../pretty/Loading";
import { backendService } from "../../service/backendService";
import { useNavigation } from "@react-navigation/core";
import { routerService } from "../../service/routerService";

export const ROUTES_NO_AUTH_OK = [ROUTES.LOGIN, ROUTES.SIGNUP, ROUTES.ACTIVATE];
const ROUTES_NO_ATTEMPT_SESSION_RENEW = [ROUTES.ACTIVATE];

interface Props {
  children?: React.ReactNode;
  routeName: ROUTES;
  refreshToken?: string;
}

export function SessionGuardWrapper({
  children,
  routeName,
  refreshToken,
}: Props) {
  const userId = useAppState("userStore", (state) => state.userId);
  const { attemptRenewSession } = useSessionLogic();
  React.useEffect(() => {
    if (
      !userId &&
      !ROUTES_NO_ATTEMPT_SESSION_RENEW.includes(routeName) &&
      !refreshToken
    ) {
      attemptRenewSession();
    }
  }, [userId]);
  const nav = useNavigation();
  const needsSession = !ROUTES_NO_AUTH_OK.includes(routeName);
  React.useEffect(() => {
    if (refreshToken) {
      console.log("found refresh token in URL, will attempt to reload session");
      (async () => {
        const sessionRes = await backendService.renewSession({ refreshToken });
        if (sessionRes.ok) {
          attemptRenewSession();
          nav.navigate(routeName, { refreshToken: "" });
        }
      })();
    }
  }, [refreshToken]);

  if ((!userId && needsSession) || refreshToken) {
    return <Loading />;
  }
  return <>{children}</>;
}
