import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { backendService } from "../service/backendService";
import { CigarReview } from "appshared-ts/lib/types/cigar-types";
import { Divider } from "../components/pretty/Divider";
import { AppLink } from "../components/util/AppLink";
import { useAppState } from "../hooks/useAppState";
import { ROUTES } from "../constants/ROUTES";
import { fmtDay } from "../utils/dateUtils";
import { useFocusEffect } from "@react-navigation/native";
import { PageTitle } from "../components/pretty/PageTitle";

export const MyReviewsScreen = asScreen(() => {
  const userId = useAppState("userStore", (state) => state.userId);
  const [reviews, setReviews] = React.useState<CigarReview[]>([]);

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const res = await backendService.getMyCigarReviews();
        if (res.ok) {
          setReviews(res.data!);
        }
      })();
    }, [])
  );

  return (
    <View style={T("wp100 aic")}>
      <PageTitle text="My reviews" />

      {reviews.map(({ cigar, review }) => (
        <AppLink
          style={T("wp100")}
          key={`${cigar.cigarId}-${review.reviewTs}`}
          route={ROUTES.CIGAR_REVIEW_DETAILS}
          params={{
            cigarIdStr: cigar.cigarId,
            userIdStr: userId,
            reviewTsStr: review.reviewTs,
          }}
        >
          <View style={T("flx-row jcsb wp100 mt4 mb3 aic pl1 pr2")}>
            <View style={T("wp80")}>
              <Text style={T("f3")}>{cigar.name}</Text>
              <Text style={T("f1 pl3")}>{fmtDay(review.reviewTs)}</Text>
            </View>
            <Text style={T("revolYellow f3")}>{review.overall}</Text>
          </View>
        </AppLink>
      ))}
    </View>
  );
});
