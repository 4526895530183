import { BurnQuality } from "appshared-ts/lib/types/cigar-types";
import React from "react";
import { useAppContext } from "../components/wrapper/AppContextWrapper";
import { defaultActiveReviewState } from "../state/activeReviewState";
import { lists } from "../utils/lists";
import { useAppState } from "./useAppState";

export function useActiveReviewStep() {
  const activeStep = useAppState(
    "activeReviewStore",
    (state) => state.reviewStepIndex
  );
  const { activeReviewStore } = useAppContext();

  return {
    activeStep,
    nextStep() {
      activeReviewStore.shallowSet("reviewStepIndex", activeStep + 1);
    },
    prevStep() {
      activeReviewStore.shallowSet(
        "reviewStepIndex",
        activeStep === 0 ? 0 : activeStep - 1
      );
    },
  };
}

export function useReviewBurn() {
  const burnQuality = useAppState("activeReviewStore", (state) => state.burn);
  const burnNotes = useAppState(
    "activeReviewStore",
    (state) => state.burnNotes
  );
  const { activeReviewStore } = useAppContext();
  return {
    burnQuality,
    burnNotes,
    setBurnQuality(burn: BurnQuality) {
      activeReviewStore.shallowSet("burn", burn);
    },
    setBurnNotes(notes: string) {
      activeReviewStore.shallowSet("burnNotes", notes);
    },
  };
}

export function useReviewSimpleFlavors() {
  const strength = useAppState("activeReviewStore", (state) => state.strength);
  const { activeReviewStore } = useAppContext();
  return {
    strength,
    setStrength(newStrength: string) {
      activeReviewStore.shallowSet("strength", newStrength);
    },
  };
}

export function useReviewCanProceed() {
  const canProceed = useAppState(
    "activeReviewStore",
    (state) => state.canProceed
  );
  const { activeReviewStore } = useAppContext();
  return {
    canProceed,
    setCanProceed(canProceed: boolean) {
      activeReviewStore.shallowSet("canProceed", canProceed);
    },
  };
}

export function useReviewFlavors() {
  const flavors = useAppState("activeReviewStore", (state) => state.flavors);
  const { activeReviewStore } = useAppContext();
  return {
    flavors,
    addFlavor(flavor: string) {
      const without = flavors.filter((item) => item !== flavor);
      activeReviewStore.shallowSet("flavors", [...without, flavor]);
    },
    removeFlavor(flavor: string) {
      activeReviewStore.shallowSet(
        "flavors",
        flavors.filter((item) => item !== flavor)
      );
    },
  };
}

export function useSetCigarReviewId({
  cigarId,
  purchaseTs,
}: {
  cigarId: number;
  purchaseTs?: number | null;
}) {
  const { activeReviewStore } = useAppContext();
  React.useEffect(() => {
    console.log("updating", purchaseTs);
    activeReviewStore.update((state) => ({
      ...defaultActiveReviewState,
      cigarId,
      purchaseTs,
    }));
  }, [cigarId]);
}

export function useReviewNotes() {
  const reviewNotes = useAppState(
    "activeReviewStore",
    (state) => state.reviewNotes
  );
  const { activeReviewStore } = useAppContext();
  return {
    reviewNotes,
    setReviewNotes(notes: string) {
      activeReviewStore.shallowSet("reviewNotes", notes);
    },
  };
}

export function useOverallReview() {
  const overall = useAppState("activeReviewStore", (state) => state.overall);
  const { activeReviewStore } = useAppContext();
  return {
    overall,
    setOverall(score: number) {
      activeReviewStore.shallowSet("overall", score);
    },
  };
}

export function useReviewIsPublic() {
  const isPublic = useAppState("activeReviewStore", (state) => state.isPulic);
  const { activeReviewStore } = useAppContext();
  return {
    isPublic,
    setIsPublic(value: boolean) {
      activeReviewStore.shallowSet("isPulic", value);
    },
  };
}

export function useActiveReview() {
  const activeReview = useAppState("activeReviewStore", (state) => state);
  return {
    activeReview,
  };
}
