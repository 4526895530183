import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { UserId } from "appshared-ts/lib/types/user-types";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { backendService } from "../service/backendService";
import { useFocusEffect, useNavigation } from "@react-navigation/core";
import { AppLink } from "../components/util/AppLink";
import { ROUTES } from "../constants/ROUTES";
import { Navbar } from "../components/navbar/Navbar";
import { useSessionLogic } from "../hooks/logic/useSessionLogic";

const imageSize = {
  mobile: {
    height: 350,
    width: 350,
  },
};

export const ActivateUserScreen = asScreen<{
  key: string;
}>(({ key }) => {
  const [userData, setUserData] =
    React.useState<{
      userId: UserId;
      pw: string;
    } | null>(null);
  const [err, setErr] = React.useState("");
  const [working, setWorking] = React.useState(true);
  const [newPw, setNewPw] = React.useState("");
  const [confirmPw, setConfirmPw] = React.useState("");
  const [pwSuccess, setPwSuccess] = React.useState(false);
  const { attemptRenewSession } = useSessionLogic();
  const nav = useNavigation();

  async function changePw() {
    const res = await backendService.changePassword({
      oldPassword: userData!.pw,
      newPassword: newPw,
    });
    if (!res.ok) {
      setErr("Failed to update password");
    }
    setPwSuccess(true);
    setTimeout(() => {
      setPwSuccess(false);
      nav.navigate(ROUTES.MY_PURCHASES);
    }, 1000);
  }

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        // see if we have a session
        const sessionRes = await attemptRenewSession();
        if (sessionRes.ok) {
          console.log("loaded a session");
          nav.navigate(ROUTES.MY_PURCHASES);
          return;
        }
        // else we attempt activation
        const res = await backendService.activateUser({
          activationKey: key,
        });
        if (!res.ok) {
          setErr("Activation failed");
        } else {
          setUserData(res.data!);
        }
        setWorking(false);
      })();
    }, [key])
  );

  return (
    <View key="page-pane" style={T("aic hp100")}>
      {userData && userData.userId ? <Navbar /> : null}
      <View style={T("hp25")} />
      {working ? <Text style={T("tc")}>Activating your account...</Text> : null}
      <Text style={T("err")}>{err}</Text>
      {userData ? (
        <View>
          <Text style={T("tc f4")}>
            Congratulations, you have activated your account!
          </Text>
          <View style={T("hp20")} />
          <Text style={T("tc f3")}>
            Your default password is{" "}
            <Text style={T("bold revolYellow")}>{userData.pw}</Text>.
          </Text>
          {/* <Text style={T("tc f3 bold")}>{"Write it down somewhere!"}</Text> */}
          <View style={T("hp5")} />
          <Text style={T("tc f3")}>
            Please take a moment to change your password
          </Text>
          <View style={T("hp10")} />
          {userData ? (
            <>
              <TextInput
                onChangeText={(text) => setNewPw(text)}
                label="New password"
                secureTextEntry={true}
              />
              <View style={T("mt3")} />
              <TextInput
                onChangeText={(text) => setConfirmPw(text)}
                label="Confirm password"
                secureTextEntry={true}
              />
              <View style={T("hp10")} />
              <Button onPress={changePw} title="Change password" />
            </>
          ) : (
            <></>
          )}
        </View>
      ) : null}
    </View>
  );
});
