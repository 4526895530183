import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { backendService } from "../service/backendService";
import { Cigar, CigarReview } from "appshared-ts/lib/types/cigar-types";
import moment from "moment-mini-ts";
import { Divider } from "../components/pretty/Divider";
import { useDeviceSize } from "../hooks/useDeviceSize";
import { Image } from "react-native";
import { fmtDay } from "../utils/dateUtils";
import { useFocusEffect } from "@react-navigation/core";
import { FontAwesome } from "@expo/vector-icons";
import { palette } from "../constants/Colors";
import { WholeStar } from "../components/util/WholeStar";
import { FunButton } from "../components/pretty/FunButton";

const imageSize = {
  mobile: {
    height: 350,
    width: 350,
  },
};

function SectionLabel({ text }: { text: string }) {
  return <Text style={T("f3 bold")}>{text}</Text>;
}

function Section({
  children,
  shouldDisplay,
}: {
  children: React.ReactNode;
  shouldDisplay: boolean;
}) {
  if (!shouldDisplay) {
    return <></>;
  }
  return <View style={T("bg-tp wp100 mt2 mb4")}>{children}</View>;
}

export const ReviewDetailsScreen = asScreen<{
  cigarIdStr: string;
  userIdStr: string;
  reviewTsStr: string;
}>(({ cigarIdStr, userIdStr, reviewTsStr }) => {
  const cigarId = parseInt(cigarIdStr, 10);
  const userId = decodeURIComponent(userIdStr);
  const reviewTs = parseInt(reviewTsStr, 10);

  const [data, setData] =
    React.useState<{
      review: CigarReview;
      cigar: Cigar;
    } | null>(null);
  const deviceSize = useDeviceSize();
  const imgStyle = imageSize[deviceSize];

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const res = await backendService.getCigarReviewComplete({
          cigarId,
          userId,
          reviewTs,
        });
        if (res.ok) {
          const { cigar, review } = res.data!;
          setData({
            review,
            cigar,
          });
        }
      })();
    }, [cigarId, userId, reviewTs])
  );

  // brutal copy pasta from review
  // TODO: make this styling generic
  if (!data) {
    return <></>;
  }

  const {
    cigar: {
      info: { imgSrc, name },
    },
    review: {
      review: { burn, burnNotes, overall, flavors, reviewNotes, strength },
    },
  } = data!;

  const wholeStars = new Array(Math.floor(overall)).fill(null);
  const halfStar = Math.round(overall * 2) % 2 !== 0;

  return (
    <View style={T("aic hp100")}>
      <View style={T("pt6 bg-tp aic wp100")}>
        <Image style={imgStyle} source={{ uri: imgSrc }} />
        <View style={[T("absolute bg-black o-60"), imgStyle]} />
      </View>

      <View style={T("absolute bg-tp aic hp100")}>
        <View style={T("pt5")} />

        <Text style={T("f4 tc shadow-1")}>{name}</Text>
        <Text style={T("f2 tc shadow-1")}>{fmtDay(reviewTs)}</Text>
        <View style={T("flx-row wp100 jcsa bg-tp pt3")}>
          <View style={T("flx-row bg-tp")}>
            {wholeStars.map((_, index) => (
              <WholeStar size={24} key={index} />
            ))}
            {halfStar ? (
              <FontAwesome
                name="star-half-o"
                size={24}
                color={palette.revolYellow}
              />
            ) : null}
          </View>
        </View>

        <View style={T("bg-tp pt5")} />

        <Section shouldDisplay={!!strength}>
          <View style={T("flx-row bg-tp aib")}>
            <SectionLabel text="Strength" />
            <Text style={T("ml3 f2")}>{SS[`strength.${strength}`]}</Text>
          </View>
        </Section>

        <Section shouldDisplay={!!burn}>
          <View style={T("flx-row bg-tp aib")}>
            <SectionLabel text={SS["review.step-name.construction-burn"]} />
            <Text style={T("ml3 f2")}>{SS[`burn.${burn}`]}</Text>
          </View>
        </Section>
        <Section shouldDisplay={!!burnNotes}>
          <SectionLabel text={SS["review.details.burn.notes"]} />
          <Text style={T("f2")}>{burnNotes}</Text>
        </Section>

        <Section shouldDisplay={flavors && !!flavors.length}>
          <SectionLabel text={SS["review.step-name.flavors"]} />
          <View style={T("flx-row bg-tp")}>
            {flavors.map((flavor) => (
              <FunButton viewStyle={T("ma1")} filled={true} key={flavor}>
                <Text style={T("textDark pl2 pr2 bold")}>{flavor}</Text>
              </FunButton>
            ))}
          </View>
        </Section>

        <Section shouldDisplay={!!reviewNotes}>
          <View style={T("wp100 bg-tp pb2")}>
            <SectionLabel text={SS["review.details.reviewNotes"]} />
          </View>
          <Text style={T("f2")}>{reviewNotes}</Text>
        </Section>
      </View>
    </View>
  );
});
