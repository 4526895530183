import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { SS } from "../../../content/short-strings";
import { useActiveReview } from "../../../hooks/activeReviewHooks";
import { backendService } from "../../../service/backendService";
import { Text, View } from "../../Themed";
import { useFocusEffect } from "@react-navigation/core";
import { AppLink } from "../../util/AppLink";
import { ROUTES } from "../../../constants/ROUTES";

export function ReviewDoneStep() {
  const [working, setWorking] = React.useState(true);
  const { activeReview } = useActiveReview();

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const res = await backendService.saveCigarReview({
          cigarId: activeReview.cigarId!,
          review: {
            reviewTs: Date.now(),
            burn: activeReview.burn || undefined,
            burnNotes: activeReview.burnNotes,
            flavors: activeReview.flavors,
            reviewNotes: activeReview.reviewNotes,
            overall: activeReview.overall,
            strength: activeReview.strength,
          },
          permissions: {
            visibility: activeReview.isPulic ? "public" : "private",
          },
          purchaseTs: activeReview.purchaseTs,
        });
        setWorking(false);
      })();
    }, [])
  );

  if (working) {
    return <Text>{"saving review..."}</Text>;
  }
  return (
    <View style={T("bg-tp hp100")}>
      <Text style={T("f4 tc")}>{SS["review.done.thank-you"]}</Text>
      <View style={T("hp25 bg-tp")} />
      <AppLink route={ROUTES.MY_REVIEWS}>
        <Text style={T("revolYellow tc f3 underline")}>
          {"See all your reviews"}
        </Text>
      </AppLink>
    </View>
  );
}
