// identity
function iden<T>(x: T): T {
  return x;
}

// set difference, using 'compareBy' to extract a value to compare from items
function difference<T>(
  orig: T[],
  without: T[],
  compareBy: (item: T) => any = iden
): T[] {
  const seen = {};
  without.forEach((item) => {
    seen[compareBy(item)] = true;
  });
  return orig.filter((item) => !seen[compareBy(item)]);
}

// removes all elements of list orig where element[key]
// is a member of { x[key] | x in without }
// (it's like set difference but projected by a key),
function differenceByKey<S, T>(
  key: keyof Extract<S, T>,
  orig: S[],
  without: T[]
): S[] {
  const valsToRemove = without.map((item) => item[key as any]);
  return orig.filter((item) => !valsToRemove.includes(item[key as any]));
}

// find first element in list by key === val
function findBy<S>(key: keyof S, val: S[typeof key], collection: S[]) {
  return collection.find((item) => item[key] === val);
}

// filter list by item[key] !== val
function filterByNot<S>(key: keyof S, val: S[typeof key], collection: S[]) {
  return collection.filter((item) => item[key] !== val);
}

// get list unique by the comparator, default to identity
function uniq<T>(list: T[], compareBy: (a: T) => any = iden): T[] {
  const newList: T[] = [];
  const seen = new Map();
  list.forEach((item) => {
    const comparisonKey = compareBy(item);
    if (!seen.has(comparisonKey)) {
      seen.set(comparisonKey, true);
      newList.push(item);
    }
  });
  return newList;
}

function move<T>(list: T[], origIndex: number, targetIndex: number) {
  if (!list.length) {
    return list;
  }
  const without = [...list];
  without.splice(origIndex, 1);
  return [
    ...without.slice(0, targetIndex),
    list[origIndex],
    ...without.slice(targetIndex),
  ];
}

// divides the list into groups of equal (plus remainder) size
function partition<T>(list: T[], groups: number, groupSize: number): T[][] {
  const res: T[][] = [];
  for (let i = 0; i < groups; i += 1) {
    const start = i * groupSize;
    const thisPart = list.slice(start, start + groupSize);
    res.push(thisPart);
  }
  return res;
}

function partitionInto<T>(list: T[], groups: number): T[][] {
  return partition(list, groups, Math.ceil(list.length / groups));
}
function partitionsOf<T>(list: T[], groupSize: number): T[][] {
  return partition(list, Math.ceil(list.length / groupSize), groupSize);
}

export const lists = {
  iden,
  findBy,
  difference,
  differenceByKey,
  filterByNot,
  uniq,
  move,
  partitionInto,
  partitionsOf,
};
