import { NavigationContainerRef } from "@react-navigation/core";

let navRef: NavigationContainerRef;

function getCurrentRoute(): string | null {
  const route = navRef.getCurrentRoute();
  if (route === undefined) {
    return null;
  }
  return route.name;
}

export const routerService = {
  setNavRef: (ref: NavigationContainerRef) => (navRef = ref),
  getCurrentRoute,
};
