import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { backendService } from "../service/backendService";
import { useNavigation } from "@react-navigation/core";
import { ROUTES } from "../constants/ROUTES";
import { Image, ScrollView } from "react-native";
import { Cigar } from "appshared-ts/lib/types/cigar-types";
import { palette } from "../constants/Colors";
import { Divider } from "../components/pretty/Divider";

const imgStyle = {
  height: 50,
  width: 50,
};

export const CigarSearchResultScreen = asScreen<{
  searchTermEncoded: string;
}>(({ searchTermEncoded }) => {
  const searchTerm = decodeURIComponent(searchTermEncoded);
  const [searchResults, setSearchResults] = React.useState<Cigar[]>([]);
  const nav = useNavigation();
  React.useEffect(() => {
    (async () => {
      const searchRes = await backendService.searchCigars({
        searchTermEncoded,
      });
      // could attach to global state, or just keep here
      setSearchResults(searchRes.data || []);
    })();
  }, [searchTerm]);
  const goToReviewPage = (cigarId: number) => () => {
    nav.navigate(ROUTES.CIGAR_REVIEW, { cigarIdStr: cigarId });
  };

  return (
    <ScrollView contentContainerStyle={T("aic")}>
      <View style={T("aic mb5")}>
        <View style={T("flx-row jcc pb4")}>
          <Text style={T("f4 pr2")}>Cigars matching: </Text>
          <Text style={T("f4 bold")}>{searchTerm}</Text>
        </View>
        <Divider />
      </View>

      {searchResults.map(({ cigarId, info: { name, imgSrc } }) => (
        <View key={cigarId} style={T("flx-row aic mt3 pl4 wp90")}>
          <Image style={imgStyle} source={{ uri: imgSrc }} />
          <Text style={T("f3 pl3")} onPress={goToReviewPage(cigarId)}>
            {name}
          </Text>
        </View>
      ))}
    </ScrollView>
  );
});
