import { Store } from "apputils/lib/util/createStore";
import { BurnQuality } from "appshared-ts/lib/types/cigar-types";

export interface ActiveReviewState {
  cigarId: number | null;
  burn: BurnQuality | null;
  burnNotes: string;
  flavors: string[];
  reviewNotes: string;
  overall: number;
  isPulic: boolean;
  purchaseTs?: number | null;
  strength: string;
  // controls
  reviewStepIndex: number;
  canProceed: boolean;
}

export const defaultActiveReviewState: ActiveReviewState = {
  overall: 0,
  cigarId: null,
  burn: null,
  burnNotes: "",
  reviewNotes: "",
  strength: "",
  flavors: [],
  canProceed: true,
  reviewStepIndex: 0,
  isPulic: false,
};
