/*
 * This should be 'user logic' not session logic.
 **/

import { Result } from "appshared-ts/lib/Result";
import { UserInfo, NewAccountInfo } from "appshared-ts/lib/types/user-types";

import { backendService } from "../../service/backendService";
import { DEFAULT_ROUTE, ROUTES } from "../../constants/ROUTES";
import { useAppContext } from "../../components/wrapper/AppContextWrapper";
import { defaultUserState } from "../../state/userState";
import { routerService } from "../../service/routerService";
import { useNavigation } from "@react-navigation/core";

const NO_LOGIN_REDIRECT_ROUTES = [ROUTES.SIGNUP, ROUTES.ACTIVATE];

export function useSessionLogic() {
  const { userStore, miscStore } = useAppContext();
  const navigation = useNavigation();

  async function loadUserData(): Promise<Result<null>> {
    const userRes = await backendService.getUserData();
    if (!userRes.ok) {
      return Result.ERR("no session");
    }
    console.log("loaded user", userRes.data);
    userStore.update((_oldState) => userRes.data!);
    return Result.OK(null);
  }
  async function loadInitialData(): Promise<Result<null>> {
    const userRes = await loadUserData();
    if (!userRes.ok) {
      return userRes;
    }
    return Result.OK(null);
  }

  async function initSession(
    userId: string,
    password: string,
    redirectRoute: ROUTES = DEFAULT_ROUTE
  ): Promise<Result<null>> {
    const loginRes = await backendService.login({
      userId,
      password,
    });
    if (!loginRes.ok) {
      return Result.ERR(loginRes.error);
    }
    const initRes = await loadInitialData();
    if (!initRes.ok) {
      return initRes;
    }
    navigation.navigate(redirectRoute);
    return Result.OK(null);
  }

  // Here we ping a 'get user data' endpoint that
  // tries to use any cookies on our session to
  // load the user data.
  // If we don't have a session, navigate to the 'login' route
  // If we do, update the userStore with the user data.
  async function attemptRenewSession(): Promise<Result<null>> {
    const res = await loadInitialData();
    if (
      !res.ok &&
      !NO_LOGIN_REDIRECT_ROUTES.includes(
        routerService.getCurrentRoute() as ROUTES
      )
    ) {
      navigation.navigate(ROUTES.LOGIN);
    }
    return res;
  }

  // also take a router here,
  // and on success:
  //  - log in
  //  - nav to landing
  async function signUpAccount(data: NewAccountInfo): Promise<Result<null>> {
    const createRes = await backendService.newAccount({
      ...data,
    });
    if (createRes.ok) {
      return initSession(data.userId, data.password);
    }
    return Result.ERR(`Error - ${createRes.error}`);
  }

  async function saveUser(
    update: Partial<{
      info: Partial<UserInfo>;
    }>
  ): Promise<Result<null>> {
    const saveRes = await backendService.updateUser(update);
    if (!saveRes.ok) {
      return Result.ERR("Failed to save user");
    }
    await loadUserData();
    return Result.OK(null);
  }

  function resetUser(userId: string) {
    userStore.update((_state) => ({
      ...defaultUserState,
      id: userId,
    }));
  }

  return {
    initSession,
    attemptRenewSession,
    signUpAccount,
    saveUser,
    loadUserData,
    resetUser,
  };
}
