import * as React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { useSessionLogic } from "../hooks/logic/useSessionLogic";
import {
  Banner,
  Divider,
} from "../components/feature/signup-login/SignupLoginCommon";
import { AppLink } from "../components/util/AppLink";
import { ROUTES } from "../constants/ROUTES";
import { inputService } from "../service/inputService";

export const SignupScreen = asScreen(() => {
  const [email, setEmail] = React.useState("");
  const [pw, setPw] = React.useState("");
  const [verifyPw, setVerifyPw] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [errMsg, setErrMsg] = React.useState("");

  const { signUpAccount } = useSessionLogic();

  async function attemptSignup() {
    if (verifyPw !== pw) {
      setErrMsg(SS["signup.password.nomatch"]);
      return;
    }
    setErrMsg("");
    const { ok, data } = inputService.phoneAsUserId(email);
    if (!ok) {
      return;
    }
    const userId = data!;
    const res = await signUpAccount({
      userId,
      password: pw,
      info: {
        firstName,
        lastName,
      },
    });
    if (!res.ok) {
      setErrMsg("failed to create user");
    }
  }

  return (
    <View style={T("aic wp100")}>
      <View style={T("hp10")} />
      <Banner />
      <View style={T("hp5")} />
      <Divider />
      <View style={T("hp10")} />

      <View style={T("wp100")}>
        <Text style={T("f2 tc")}>{SS["signup.blurb"]}</Text>
      </View>
      <View style={T("hp5")} />

      <View style={T("wp100")}>
        {errMsg ? <Text style={T("red")}>{errMsg}</Text> : null}
        <TextInput
          onChangeText={(text) => setEmail(text)}
          placeholder={SS["signup.email"]}
        />
        <TextInput
          onChangeText={(text) => setPw(text)}
          placeholder={SS["signup.pw"]}
          secureTextEntry={true}
        />
        <TextInput
          onChangeText={(text) => setVerifyPw(text)}
          placeholder={SS["signup.pw.confirm"]}
          secureTextEntry={true}
        />
        <TextInput
          onChangeText={(text) => setFirstName(text)}
          placeholder={SS["signup.name.first"]}
        />
        <TextInput
          onChangeText={(text) => setLastName(text)}
          placeholder={SS["signup.name.last"]}
        />
        <View style={T("pt5")} />
        <Button onPress={attemptSignup} title={SS["signup.submit"]} />
      </View>

      <View style={T("hp15")} />
      <AppLink route={ROUTES.LOGIN}>
        <Text style={T("link")}>{SS["login.back-to"]}</Text>
      </AppLink>
      <View></View>
    </View>
  );
});
