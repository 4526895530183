import React from "react";

import { FontAwesome } from "@expo/vector-icons";
import { palette } from "../../constants/Colors";

const DEFAULT_SIZE = 36;

interface Props {
  size?: number;
}

export function WholeStar({ size }: Props) {
  return (
    <FontAwesome
      name="star"
      size={size || DEFAULT_SIZE}
      color={palette.revolYellow}
    />
  );
}
