import AsyncStorage from "@react-native-async-storage/async-storage";
import { backendService } from "./backendService";

import { ENV } from "./env";

const ATTEMPT_RELOAD_TO_VSN = "ATTEMPT_RELOAD_TO_VSN";

export const versionService = {
  async attemptSync() {
    console.log("WEBAPP VSN:", ENV.GIT_REV);
    if (ENV.GIT_REV === "dev") {
      console.log("WEBAPP VSN is 'dev' - skipping check");
      return;
    }
    const confRes = await backendService.getWebappConf({ gitRev: ENV.GIT_REV });
    if (!confRes.ok) {
      console.log(
        "failed to check for latest version from server, continuing with cached assets"
      );
      return;
    }
    const { targetVsn } = confRes.data!;
    if (targetVsn === ENV.GIT_REV) {
      console.log("webapp version is up to date");
      return;
    }
    console.log("webapp version does not match target:", targetVsn);
    // else we attempt to sync with latest version
    const attemptVersion = await AsyncStorage.getItem(ATTEMPT_RELOAD_TO_VSN);
    if (attemptVersion === targetVsn) {
      console.log(
        "already attempted a hard reload, continuing with cached version"
      );
      return;
    }
    try {
      console.log("attempt to remove all service workers");
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    } catch (e) {
      console.error("failed to remove service workers");
    }
    console.log("will hard reload page in order to bust cache");
    await AsyncStorage.setItem(ATTEMPT_RELOAD_TO_VSN, targetVsn);
    setTimeout(() => {
      window.location.reload(true);
    }, 100);
  },
};
