import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { backendService } from "../service/backendService";
import { TouchableHighlight, TouchableOpacity } from "react-native";
import { Portal, Modal } from "react-native-paper";
import { palette } from "../constants/Colors";
import { useNavigation } from "@react-navigation/core";
import { ROUTES } from "../constants/ROUTES";

const imageSize = {
  mobile: {
    height: 350,
    width: 350,
  },
};

export const ChangePasswordScreen = asScreen<{
  prevPw?: string;
  userId?: string;
}>(({ prevPw, userId }) => {
  const [pw, setPw] = React.useState(prevPw || "");
  const [newPw, setNewPw] = React.useState("");
  const [confirmPw, setConfirmPw] = React.useState("");
  const [err, setErr] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const nav = useNavigation();

  async function resetPw() {
    const res = await backendService.changePassword({
      oldPassword: pw,
      newPassword: newPw,
      ...(userId ? { userId } : null),
    });
    if (!res.ok) {
      setErr("Failed to update password");
    }
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      setPw("");
      nav.navigate(ROUTES.MY_PURCHASES);
    }, 1000);
  }

  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);

  async function totallyResetPassword() {
    hideModal();
    await backendService.resetPassword();
  }

  if (success) {
    return (
      <View key="page-pane" style={T("hp100")}>
        <View style={T("hp25")} />
        <Text style={T("f4 revolYellow")}>Password updated successfully!</Text>
      </View>
    );
  }
  const modalContainerStyle = {
    backgroundColor: palette.revolGray,
    padding: 20,
  };

  return (
    <View key="page-pane" style={T("hp100")}>
      <Portal>
        <Modal
          visible={modalVisible}
          onDismiss={hideModal}
          contentContainerStyle={modalContainerStyle}
        >
          <Text style={T("f2 bold tc pb2")}>
            {"Really reset your password?"}
          </Text>
          <Text style={T("f2 tc pb4")}>
            {
              "This will trigger a text to your phone with a link to create a new password."
            }
          </Text>
          <Button onPress={totallyResetPassword} title="Reset password" />
        </Modal>
      </Portal>
      <View style={T("hp25")} />
      <Text style={T("f4 revolYellow")}>Change your password</Text>
      <View style={T("mt4")} />
      <TextInput
        value={pw}
        onChangeText={(text) => setPw(text)}
        label="Current password"
        secureTextEntry={true}
      />
      <View style={T("mt3")} />
      <TextInput
        onChangeText={(text) => setNewPw(text)}
        label="New password"
        secureTextEntry={true}
      />
      <View style={T("mt3")} />
      <TextInput
        onChangeText={(text) => setConfirmPw(text)}
        label="Confirm password"
        secureTextEntry={true}
      />
      <View style={T("hp10")} />
      <Button onPress={resetPw} title="Change password" />
      <View style={T("hp10")} />
      <TouchableOpacity onPress={showModal}>
        <Text style={T("f2 revolYellow")}>
          Click here to reset your password
        </Text>
      </TouchableOpacity>
    </View>
  );
});
