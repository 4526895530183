import React from "react";

import { Store } from "apputils/lib/util/createStore";
import {
  AppContextData,
  useAppContext,
} from "../components/wrapper/AppContextWrapper";

export function useAppState<K extends keyof AppContextData, S>(
  key: K,
  selector: (state: AppContextData[K]["state"]) => S
) {
  const context = useAppContext();
  const store: Store<any> = context[key];
  const [localState, setLocalState] = React.useState<S>(
    // start with the current value of the selector
    selector(store.state)
  );
  React.useEffect(() => {
    // subscribe to update the React local state on changes,
    // and thus incur whatever redrawing React would normally ential this way
    const unsubscribe = store.subscribe(selector, (newState) => {
      // console.trace("change:", newState);
      setLocalState(newState);
    });
    return unsubscribe;
  }, [selector, store]);
  return localState;
}
