import { Result } from "appshared-ts/lib/Result";

const phoneRegex =
  /^\D*1?\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*(\d)\D*$/;

export const inputService = {
  phoneAsUserId(phone: string): Result<string> {
    if (!phone) {
      return Result.ERR("phone is empty");
    }
    const cleaned = phone.trim();
    const match = phoneRegex.exec(cleaned);
    if (!match) {
      return Result.ERR("not a phone number");
    }
    return Result.OK(match.slice(1, 11).join(""));
  },
};

export const test = { phoneRegex };
