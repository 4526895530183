export enum ROUTES {
  LOGIN = "login",
  SIGNUP = "signup",
  USER_CONFIG = "user-config",
  MY_REVIEWS = "my-reviews",
  MY_PURCHASES = "my-purchases",
  CIGAR_SEARCH_RESULTS = "cigar-search-results",
  CIGAR_REVIEW = "cigar-review",
  CIGAR_REVIEW_DETAILS = "cigar-review-details",
  ACTIVATE = "activate",
  CHANGE_PASSWORD = "change-password",
}

export const DEFAULT_ROUTE = ROUTES.MY_REVIEWS;
