export const SS = {
  "title": "Cigar Journal",

  // login
  "login.userId": "10 digit phone number",
  "login.pw": "Password",
  "login.submit": "Login",
  "login.back-to": "Meant to login?",
  // submit
  "signup.blurb": "Please enter your information to proceed",
  "signup.email": "Email",
  "signup.pw": "Password",
  "signup.pw.confirm": "Confirm Password",
  "signup.name.first": "First Name",
  "signup.name.last": "Last Name",
  "signup.submit": "Register",
  "signup.password.nomatch": "Passwords do not match",
  "signup.prompt": "Not a member yet?",
  "signup.here": "Sign up here",

  "search": "Search",
  "search.cigars": "Search for a cigar",

  // review
  "review.step-name.construction-burn": "Burn",
  "review.step-name.construction-draw": "Construction",
  "review.step-name.flavors": "Flavor profile",
  "review.step-name.notes": "Notes",
  "review.step-name.overall": "Overall score",
  "review.step-name.simple-flavors": "Strength",
  "review.start": "Get started",
  "review.next.step": "Continue",
  "review.previous.step": "Back",

  "review.done.thank-you": "Thank you for your review!",
  "review.finish": "Finish review",

  "review.notes.placeholder": "Any other notes",
  "review.burn.notes.placeholder": "Other burn notes",

  // review details
  "review.details.burn.notes": "Burn notes",
  "review.details.reviewNotes": "Other notes",

  // random
  "burn.consistent": "Consistent",
  "burn.inconsistent": "Inconsistent",
  "strength.mild": "Mild",
  "strength.medium": "Medium",
  "strength.strong": "Strong",
};
