import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { Ionicons } from "@expo/vector-icons";

import { Button, TextInput, View } from "../Themed";
import { SS } from "../../content/short-strings";
import { useNavigation } from "@react-navigation/core";
import { ROUTES } from "../../constants/ROUTES";
import { useDeviceSize } from "../../hooks/useDeviceSize";
import { palette } from "../../constants/Colors";

const DIMENSIONS = {
  mobile: {
    iconSize: 30,
    searchWidth: 200,
  },
};

// <Button onPress={search} title={SS["search"]} />

export function CigarSearchInput() {
  const navigation = useNavigation();
  const deviceSize = useDeviceSize();
  const { iconSize, searchWidth } = DIMENSIONS[deviceSize];
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(false);
  const textInputRef = React.useRef<any>();

  async function search() {
    navigation.navigate(ROUTES.CIGAR_SEARCH_RESULTS, {
      searchTermEncoded: encodeURIComponent(searchTerm),
    });
  }

  function toggleShowSearch() {
    if (showSearch) {
      setShowSearch(false);
      return;
    }
    setShowSearch(true);
    setTimeout(() => {
      textInputRef.current.focus();
    });
  }

  return (
    <View
      style={[
        T("flx-i flx-row jcfe pa2"),
        {
          borderColor: palette.revolYellow,
        },
      ]}
    >
      {!showSearch && (
        <Ionicons
          onPress={toggleShowSearch}
          name="md-search"
          size={iconSize}
          color={palette.revolYellow}
        />
      )}
      {showSearch && (
        <TextInput
          ref={textInputRef}
          onBlur={toggleShowSearch}
          onSubmitEditing={search}
          style={[T("ml3"), { width: searchWidth }]}
          onChangeText={(text) => setSearchTerm(text)}
          placeholder={SS["search.cigars"]}
        />
      )}
    </View>
  );
}
