import React from "react";
import { T } from "@shipt/react-native-tachyons";
import Checkbox from "expo-checkbox";

import { View, Text, TextInput } from "../../Themed";
import {
  useOverallReview,
  useReviewCanProceed,
  useReviewIsPublic,
  useReviewNotes,
} from "../../../hooks/activeReviewHooks";
import { textInputBgColor } from "./constants";
import { SS } from "../../../content/short-strings";
import { HalvedStar } from "../../util/HalvedStar";
import { palette } from "../../../constants/Colors";

const scoreOutOf = new Array(5).fill(null);

const starSize = 48;

export function OverallReviewStep() {
  const { reviewNotes, setReviewNotes } = useReviewNotes();
  const { overall, setOverall } = useOverallReview();
  const { canProceed, setCanProceed } = useReviewCanProceed();
  const { isPublic, setIsPublic } = useReviewIsPublic();

  React.useEffect(() => {
    if (overall) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [overall]);

  return (
    <>
      <View style={T("flx-row bg-tp jcsb aib pr3 pl3")}>
        <View style={T("flx-row bg-tp")}>
          {scoreOutOf.map((_, index) => (
            <HalvedStar
              size={starSize}
              key={index}
              onLeftClick={() => setOverall(index + 0.5)}
              onRightClick={() => setOverall(index + 1.0)}
              leftActive={index + 0.5 <= overall}
              rightActive={index + 1 <= overall}
            />
          ))}
        </View>
        {overall && (
          <Text style={T("f5 revolYellow")}>{overall.toFixed(1)}</Text>
        )}
      </View>

      <View
        style={[
          T("mt3"),
          {
            backgroundColor: textInputBgColor,
          },
        ]}
      >
        <TextInput
          value={reviewNotes}
          style={T("f1")}
          multiline={true}
          disableBorder={true}
          numberOfLines={7}
          placeholder={SS["review.notes.placeholder"]}
          onChangeText={setReviewNotes}
        />
      </View>

      <View style={T("hp25 bg-tp")} />
      <View style={T("bg-tp flx-row jcc aic")}>
        <Text style={T("f2 pr3")}>Make review public?</Text>
        <Checkbox
          value={isPublic}
          onValueChange={setIsPublic}
          color={palette.revolYellow}
        />
      </View>
    </>
  );
}
