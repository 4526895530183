import React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Button, Text, View } from "../../Themed";
import { SS } from "../../../content/short-strings";

import { reviewStepOrder } from "./reviewStepOrder";
import {
  useActiveReviewStep,
  useReviewCanProceed,
} from "../../../hooks/activeReviewHooks";
import { ReviewDoneStep } from "./ReviewDoneStep";
import { Cigar } from "appshared-ts/lib/types/cigar-types";

interface Props {
  cigar: Cigar;
}

export function CigarReviewMain({ cigar }: Props) {
  const { activeStep, nextStep, prevStep } = useActiveReviewStep();
  const { stepName, Component, skippable } = reviewStepOrder[activeStep] || {};
  const { canProceed, setCanProceed } = useReviewCanProceed();
  React.useEffect(() => {
    if (activeStep === 0) {
      setCanProceed(true);
    }
  }, [activeStep]);

  const nextBtnText = (() => {
    if (!activeStep) {
      return SS["review.start"];
    }
    if (activeStep === reviewStepOrder.length - 1) {
      return SS["review.finish"];
    }
    return SS["review.next.step"];
  })();
  const prevButtonText = "Back";
  const skipButtonText = "Skip";

  if (activeStep >= reviewStepOrder.length) {
    return <ReviewDoneStep />;
  }

  return (
    <View key="review-main" style={T("bg-tp wp100 hp100")}>
      {stepName && (
        <Text style={T("f5 shadow-1")}>
          {SS[`review.step-name.${stepName}`]}
        </Text>
      )}
      <View style={T("mt3")} />
      {stepName === "begin" ? (
        <Component cigarAttrs={cigar.attributes} />
      ) : (
        <Component />
      )}

      <View
        key="continue-btn"
        style={[
          T("wp100 bg-tp flx-row"),
          {
            position: "absolute",
            bottom: 0,
          },
        ]}
      >
        {activeStep > 0 ? (
          <Button
            containerStyle={T("flx-i")}
            btnType="primary"
            title={prevButtonText}
            onPress={prevStep}
          />
        ) : null}
        {skippable ? (
          <Button
            containerStyle={T("flx-i")}
            btnType="secondary"
            title={skipButtonText}
            onPress={nextStep}
          />
        ) : null}
        {activeStep < reviewStepOrder.length ? (
          <Button
            containerStyle={T("flx-i")}
            disabled={!canProceed}
            title={nextBtnText}
            onPress={nextStep}
          />
        ) : null}
      </View>
    </View>
  );
}
