import React from "react";
import { Image } from "react-native";

interface Props {
  height: number;
  width: number;
}

export function LogoOne({ height, width }: Props) {
  return (
    <Image
      style={{ height, width }}
      source={require("../../assets/images/logo1.png")}
    />
  );
}
