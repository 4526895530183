import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { Entypo } from "@expo/vector-icons";

import { View, Text } from "../../Themed";
import { ReviewSelectable } from "./ReviewSelectable";
import { FLAVORS, FLAVOR_GROUPS } from "../../../constants/flavors";
import {
  useReviewCanProceed,
  useReviewFlavors,
} from "../../../hooks/activeReviewHooks";
import { FunButton } from "../../pretty/FunButton";
import { palette } from "../../../constants/Colors";
import { lists } from "../../../utils/lists";

// can show common flavors when we identify them
export function FlavorsReviewStep() {
  const [activeGroup, setActiveGroup] = React.useState<string | null>(null);
  const { flavors, addFlavor, removeFlavor } = useReviewFlavors();
  const { setCanProceed } = useReviewCanProceed();

  const flavorOptions = React.useMemo(() => {
    const options: string[] = activeGroup
      ? FLAVORS[activeGroup.toLowerCase()]
      : [];
    return lists.difference(options, flavors);
  }, [activeGroup, flavors]);

  React.useEffect(() => {
    if (flavors.length) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [flavors.length]);

  return (
    <View style={T("bg-tp")}>
      <View style={T("bg-tp flx-row flx-wrap mb5")}>
        {flavors.map((flavor) => (
          <FunButton
            viewStyle={T("ma1")}
            key={flavor}
            filled={true}
            onPress={() => removeFlavor(flavor)}
          >
            <Entypo
              style={T("pt1")}
              name="cross"
              size={14}
              color={palette.textDark}
            />
            <Text style={T("pl1 pr2 textDark bold")}>{flavor}</Text>
          </FunButton>
        ))}
      </View>

      <View style={T("jcc flx-row bg-tp pb3 aic")}>
        <Text style={T("f4")}>Select</Text>
        {activeGroup && <Text style={T("f3 pl3 bold")}>{activeGroup}</Text>}
        <Text style={T("f4 pl3")}>flavors:</Text>
      </View>

      <View style={T("bg-tp flx-row aic wp100 jcc flx-wrap")}>
        {activeGroup
          ? flavorOptions.map((option) => (
              <View key={option} style={T("bg-tp ma2")}>
                <ReviewSelectable
                  isActive={false}
                  text={option}
                  onPress={() => addFlavor(option)}
                />
              </View>
            ))
          : FLAVOR_GROUPS.map((group) => (
              <View key={group} style={T("bg-tp ma2")}>
                <ReviewSelectable
                  isActive={false}
                  text={group}
                  onPress={() => setActiveGroup(group)}
                />
              </View>
            ))}
      </View>
      {activeGroup && (
        <View style={T("aic bg-tp mt6")}>
          <FunButton
            btnType="secondary"
            filled={true}
            onPress={() => setActiveGroup(null)}
          >
            <Entypo name="chevron-left" size={18} color={palette.textLight} />
            <Text style={T("f1 pr2 bold")}>Back</Text>
          </FunButton>
        </View>
      )}
    </View>
  );
}
