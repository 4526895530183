/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import * as React from "react";
import {
  Text as DefaultText,
  TextInputProps,
  View as DefaultView,
  TextInput as DefaultTextInput,
  Button as DefaultButton,
  ButtonProps,
  ViewProps,
  TextProps,
  TouchableHighlight,
  TextStyle,
  ViewStyle,
} from "react-native";
import { T } from "@shipt/react-native-tachyons";

import { Colors, palette } from "../constants/Colors";

interface WithChildren {
  children?: React.ReactNode;
}

export function Text({ style, ...otherProps }: TextProps & WithChildren) {
  const color = Colors["text"];
  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View({ style, ...otherProps }: ViewProps & WithChildren) {
  const backgroundColor = Colors["background"];
  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export const TextInput = React.forwardRef(
  (
    {
      style,
      label,
      disableBorder,
      ...otherProps
    }: TextInputProps &
      WithChildren & {
        label?: string;
        disableBorder?: boolean;
      },
    ref: React.ForwardedRef<any>
  ) => {
    const overrideStyle = {
      placeholderTextColor: palette.textMed,
      borderColor: palette.revolYellow,
      color: Colors["text"],
      padding: 2,
    };

    return (
      <>
        {label ? <Text style={T("mb1")}>{label}</Text> : null}
        <DefaultTextInput
          ref={ref}
          style={[T("f3"), T(disableBorder ? "" : "ba"), overrideStyle, style]}
          {...otherProps}
        />
      </>
    );
  }
);

// can also stick an image in here:
// <Image style = { { height: 27, width:27, margin : 5 } } source = {  icon }></Image>
type BtnType = "primary" | "secondary";
export function Button({
  title,
  btnType,
  onPress,
  width,
  textStyle,
  containerStyle,
  disabled,
}: ButtonProps & {
  btnType?: BtnType;
  width?: number;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
}) {
  const colors = Colors.btn[btnType || "primary"];
  const bgStyle = {
    // flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: disabled ? palette.textLight : colors.bg,
    paddingVertical: 5,
    width,
    borderColor: palette.textDark,
    borderWidth: 1,
  } as const;
  const specViewStyle: ViewStyle = {
    backgroundColor: "transparent",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  };
  const specTextStyle: TextStyle = {
    fontWeight: "bold",
    color: disabled ? palette.revolGray : colors.text,
    height: "100%",
  };
  return (
    <TouchableHighlight
      disabled={disabled}
      onPress={onPress}
      style={[bgStyle, containerStyle]}
    >
      <View style={[specViewStyle]}>
        <Text style={[T("f2"), specTextStyle, textStyle]}>{title}</Text>
      </View>
    </TouchableHighlight>
  );
}
