import { useNavigation } from "@react-navigation/core";
import React from "react";
import { TouchableHighlight, Linking, Platform, ViewStyle } from "react-native";

import { ROUTES } from "../../constants/ROUTES";

interface Props {
  route?: ROUTES;
  externalUrl?: string;
  children: React.ReactNode;
  params?: any;
  style?: ViewStyle;
}

export function AppLink({
  route,
  externalUrl,
  children,
  params,
  style,
}: Props) {
  const nav = useNavigation();

  if (route) {
    function navToRoute() {
      nav.navigate(route!, params);
    }
    return (
      <TouchableHighlight style={style} onPress={navToRoute}>
        {children}
      </TouchableHighlight>
    );
  }

  if (externalUrl) {
    function navToUrl() {
      if (Platform.OS === "web") {
        window.open(externalUrl, "_blank");
      } else {
        Linking.openURL(externalUrl!);
      }
    }
    return (
      <TouchableHighlight style={style} onPress={navToUrl}>
        {children}
      </TouchableHighlight>
    );
  }

  throw new Error("must provide one of 'route' or 'externalUrl'");
}
