import * as Linking from "expo-linking";
import { ROUTES } from "../constants/ROUTES";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: Object.keys(ROUTES).reduce((acc, key) => {
      acc[ROUTES[key]] = ROUTES[key];
      return acc;
    }, {}),
  },
};
