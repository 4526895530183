export const palette = {
  revolWhite: "rgb(235, 235, 235)",
  revolGray: "rgb(40, 40, 40)",
  revolYellow: "rgb(238, 189, 70)",
  revolMedGray: "rgb(34, 31, 32)",

  textLight: "rgb(220, 220, 220)",
  textMed: "rgb(145, 145, 145)",
  textDark: "rgb(10, 10, 10)",

  tintColorLight: '#2f95dc',
  tintColorDark: '#ffffff',
}


export const Colors = {
  text: palette.textLight,
  background: palette.revolGray,
  inputText: '#000',
  btn: {
    primary: {
      bg: palette.revolYellow,
      text: palette.textDark,
    },
    secondary: {
      bg: palette.revolMedGray,
      text: palette.textLight,
    }
  }
}
