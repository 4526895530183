import * as React from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import { T } from "@shipt/react-native-tachyons";
import { Surface } from "react-native-paper";

import { CigarPurchase } from "appshared-ts/lib/types/purchase-types";

import { Button, Text, TextInput, View } from "../components/Themed";
import { asScreen } from "../hoc/asScreen";
import { SS } from "../content/short-strings";
import { PageTitle } from "../components/pretty/PageTitle";
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from "@react-navigation/core";
import { backendService } from "../service/backendService";
import { ROUTES } from "../constants/ROUTES";
import { fmtDay } from "../utils/dateUtils";
import { palette } from "../constants/Colors";
import { CigarId } from "appshared-ts/lib/types/cigar-types";
import { useOncePerScreenLoad } from "../hooks/useOncePerScreenLoad";
import { CF_API_CACHE_MS } from "../constants/constants";
import { useAppState } from "../hooks/useAppState";

const imgStyle = {
  height: 50,
  width: 50,
};

export const MyPurchasesScreen = asScreen(() => {
  const [purchases, setPurchases] = React.useState<CigarPurchase[]>([]);
  const [selected, setSelected] = React.useState<string>("");
  const [keyWorking, setKeyIsWorking] = React.useState<string>("");
  const nav = useNavigation();
  const userId = useAppState("userStore", (state) => state.userId);

  const getKey = (cigarId: CigarId, purchaseTs: number) =>
    `${cigarId}-${purchaseTs}`;

  async function loadPurchases() {
    const res = await backendService.getMyPurchases();
    if (res.ok) {
      setPurchases(res.data!);
    }
  }

  React.useEffect(() => {
    if (userId) {
      loadPurchases();
    }
  }, [userId]);

  useOncePerScreenLoad(() => {
    loadPurchases();
  });

  async function clearPurchase(cigarId: CigarId, purchaseTs: number) {
    setKeyIsWorking(getKey(cigarId, purchaseTs));
    setSelected("");
    const res = await backendService.clearPurchase({ cigarId, purchaseTs });
    if (res.ok) {
      setTimeout(() => {
        loadPurchases();
        setKeyIsWorking("");
      }, CF_API_CACHE_MS);
    }
  }

  const goToReviewPage = (cigarId: number, purchaseTs: number) => () => {
    // also pass param to link this with the purchase
    nav.navigate(ROUTES.CIGAR_REVIEW, {
      cigarIdStr: cigarId,
      purchaseTsStr: purchaseTs,
    });
  };

  return (
    <ScrollView contentContainerStyle={T("hp100 wp100 aic")}>
      <PageTitle text="My purchases" />
      {purchases.map(
        ({
          cigar: {
            cigarId,
            info: { name, imgSrc },
          },
          purchaseTs,
        }) => {
          const key = getKey(cigarId, purchaseTs);
          const isSelected = key === selected;
          const isWorking = key === keyWorking;
          return (
            <View
              key={key}
              style={[
                T("aic mt3 ml4 mr4 wp90"),
                {
                  borderColor: palette.revolYellow,
                },
                isSelected ? T("ba") : null,
              ]}
            >
              <TouchableOpacity
                style={T("wp100")}
                onPress={() =>
                  isSelected ? setSelected("") : setSelected(key)
                }
              >
                <Surface style={T("wp100")}>
                  <View style={T("flx-row aic wp100")}>
                    <Image style={imgStyle} source={{ uri: imgSrc }} />
                    <View style={T("pl3 flx-i")}>
                      <Text style={T("f3")}>{name}</Text>
                      <Text>{fmtDay(purchaseTs)}</Text>
                    </View>
                  </View>
                </Surface>
              </TouchableOpacity>
              {isWorking ? (
                <View style={T("o-75 absolute hp100 wp100")}></View>
              ) : null}
              {isSelected ? (
                <View style={[T("flx-row aic wp100")]}>
                  <Button
                    containerStyle={T("flx-i")}
                    onPress={goToReviewPage(cigarId, purchaseTs)}
                    title="Review"
                  />
                  <Button
                    containerStyle={T("flx-i")}
                    onPress={() => clearPurchase(cigarId, purchaseTs)}
                    title="Clear purchase"
                  />
                </View>
              ) : null}
            </View>
          );
        }
      )}
    </ScrollView>
  );
});
