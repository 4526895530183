import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";
import { View } from "../components/Themed";
import { ROUTES } from "../constants/ROUTES";
import { ActivateUserScreen } from "../screens/ActivateUserScreen";
import { ChangePasswordScreen } from "../screens/ChangePasswordScreen";
import { CigarReviewScreen } from "../screens/CigarReviewScreen";
import { CigarSearchResultScreen } from "../screens/CigarSearchResultScreen";
import { LandingScreen } from "../screens/LandingScreen";
import { LoginScreen } from "../screens/LoginScreen";
import { MyPurchasesScreen } from "../screens/MyPurchasesScreen";
import { MyReviewsScreen } from "../screens/MyReviewsScreen";
import { ReviewDetailsScreen } from "../screens/ReviewDetailsScreen";
import { SignupScreen } from "../screens/SignupScreen";

import { routerService } from "../service/routerService";
import LinkingConfiguration from "./LinkingConfiguration";

const Stack = createStackNavigator();
const WrapperStack = createStackNavigator();

// <Stack.Navigator screenOptions={{ headerShown: false }}>
//   <Stack.Screen name={"app"} component={AppNavigation} />
// </Stack.Navigator>

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  const navigationRef = React.useRef<any>();
  const routeNameRef = React.useRef<any>();

  React.useEffect(() => {
    routerService.setNavRef(navigationRef.current);
  }, [navigationRef.current]);

  function onStateChange() {
    routeNameRef.current = navigationRef.current.getCurrentRoute().name;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      onStateChange={onStateChange}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name={ROUTES.MY_PURCHASES}
          component={MyPurchasesScreen}
        />
        <Stack.Screen name={ROUTES.MY_REVIEWS} component={MyReviewsScreen} />
        <Stack.Screen name={ROUTES.ACTIVATE} component={ActivateUserScreen} />
        <Stack.Screen
          name={ROUTES.CHANGE_PASSWORD}
          component={ChangePasswordScreen}
        />

        <Stack.Screen
          name={ROUTES.CIGAR_REVIEW_DETAILS}
          component={ReviewDetailsScreen}
        />
        <Stack.Screen name={ROUTES.LOGIN} component={LoginScreen} />
        <Stack.Screen name={ROUTES.SIGNUP} component={SignupScreen} />
        <Stack.Screen
          name={ROUTES.CIGAR_SEARCH_RESULTS}
          component={CigarSearchResultScreen}
        />
        <Stack.Screen
          name={ROUTES.CIGAR_REVIEW}
          component={CigarReviewScreen}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
