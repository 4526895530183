import React from "react";
import { versionService } from "../service/versionService";

const MAX_APP_AGE_MS = 1000 * 60 * 60 * 24; // one day

export function useVersionLogic() {
  const [lastCheck, setLastCheck] = React.useState<number | null>(null);
  React.useEffect(() => {
    if (!lastCheck || lastCheck < Date.now() - MAX_APP_AGE_MS) {
      versionService.attemptSync();
    }
  }, [lastCheck]);
}
