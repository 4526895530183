import * as React from "react";
import { T } from "@shipt/react-native-tachyons";

import { Text } from "../Themed";
import { Divider } from "./Divider";

interface Props {
  text: string;
}

export function PageTitle({ text }: Props) {
  return (
    <>
      <Text style={T("f5")}>{text}</Text>
      <Divider style={T("mt2 mb5")} />
    </>
  );
}
