import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { build, T } from '@shipt/react-native-tachyons';
import { Provider as PaperProvider } from 'react-native-paper';

import { AppContextWrapper } from './components/wrapper/AppContextWrapper';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { palette } from './constants/Colors';
import { View } from './components/Themed';
import { Dimensions } from 'react-native';
import { dimensionService } from './service/dimensionService';
import { versionService } from './service/versionService';
import { useVersionLogic } from './hooks/useVersionLogic';


const classes = {
  "b--rev-white": {
    borderColor: palette.revolWhite,
  },
  "b--rev-m-gray": {
    borderColor: palette.revolMedGray,
  },
  "link": {
    fontWeight: "bold",
  },
  "bg-tp": {
    backgroundColor: "transparent",
  },
  "text-light": {
    color: palette.textLight,
  },
  "bg-rg": {
    backgroundColor: palette.revolGray,
  },
  "dark": {
    color: palette.textDark,
  },
  "shadow-1": {
    textShadowColor: 'rgba(0, 0, 0, 0.9)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 5
  },
  "err": {
    color: "red",
  },
};

// Run build
build({
  rem: 16,
  colors: palette,
  styles: classes,
});

let firstDraw = true;
const initialLoadElem = document.getElementById("initial-loader");

export default function App() {
  console.log("rendering app");

  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  dimensionService.setIfNotSet();
  const { height, width } = dimensionService.getDimensions();
  useVersionLogic();


  if (!isLoadingComplete) {
    return null;
  } else {
    if (firstDraw) {
      initialLoadElem!.remove();
    }
    firstDraw = false;
    return (
      <View style={{ height, width }}>
      <SafeAreaProvider>
        <PaperProvider>
        <AppContextWrapper>
          <Navigation colorScheme={colorScheme} />
        </AppContextWrapper>
        </PaperProvider>
      </SafeAreaProvider>
      </View>
    );
  }
}
