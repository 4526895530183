import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import * as React from "react";

export function useOncePerScreenLoad(fn: () => void | Promise<void>) {
  const isFocused = useIsFocused();
  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        fn();
      }
    }, [isFocused])
  );
}
