import React from "react";
import { T } from "@shipt/react-native-tachyons";
import { FontAwesome } from "@expo/vector-icons";

import { View } from "../Themed";
import { palette } from "../../constants/Colors";
import { TouchableOpacity } from "react-native";
import { WholeStar } from "./WholeStar";

interface Props {
  size?: number;
  leftActive?: boolean;
  rightActive?: boolean;
  onLeftClick: () => void;
  onRightClick: () => void;
}

const DEFAULT_SIZE = 36;

export function HalvedStar({
  size,
  leftActive,
  rightActive,
  onLeftClick,
  onRightClick,
}: Props) {
  const actualSize = size || DEFAULT_SIZE;
  const inner = (() => {
    if (leftActive && rightActive) {
      return <WholeStar size={actualSize} />;
    }
    if (leftActive && !rightActive) {
      return (
        <FontAwesome
          name="star-half-o"
          size={actualSize}
          color={palette.revolYellow}
        />
      );
    }
    return (
      <FontAwesome
        name="star-o"
        size={actualSize}
        color={palette.revolYellow}
      />
    );
  })();
  return (
    <>
      <View style={T("bg-tp")}>
        {inner}
        <View style={T("bg-tp absolute wp100 hp100 flx-row")}>
          <TouchableOpacity
            key="left-press"
            onPress={onLeftClick}
            style={T("wp50 hp100")}
          />
          <TouchableOpacity
            key="right-press"
            onPress={onRightClick}
            style={T("wp50 hp100")}
          />
        </View>
      </View>
    </>
  );
}
